const debounce = (func:any, wait:any) => {
    let timeout:any;
  
    return function executedFunction(...args:any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

export default debounce;