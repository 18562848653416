import React from 'react';
import { useTelegram } from '../hooks/useTelegram';

const Toggle = ({...props}) => {

    const {user,tg} = useTelegram();


    const onClickHandle= () =>{
        tg?.HapticFeedback?.selectionChanged();
        props.setState(!props.state)
    }

    return (
        <div className={`w-[150px] h-[71px] ${props.className}`}>
            <p className='text-[#37456C]'>{props.label}</p>
        <div className={`w-[150px] h-[60px] bg-[#223056] select-none rounded-md flex flex-wrap `} onClick={onClickHandle}>
            {props.state
                ? <div className={`absolute w-[70px] h-[54px] rounded-l-md rounded-r-sm mt-[3px] ml-[3px] ${props.redngreen?' bg-[#27ff32]':' bg-[#0063C8]'} bg-opacity-30 duration-300`}></div>
                : <div className={`absolute w-[70px] h-[54px] rounded-r-md rounded-s-sm mt-[3px] ml-[77px] ${props.redngreen?' bg-[#c80000]':' bg-[#0063C8]'} bg-opacity-30 duration-300`}></div>
            }
            <div className={`left w-[75px] h-[60px] flex justify-center duration-300 items-center ${props.state ? 'text-[#C6CCDD]' : 'text-[#151B28]'}`}>{props.leftName}</div>
            <div className={`right w-[75px] h-[60px] flex justify-center duration-300 items-center ${props.state ? 'text-[#151B28]' : 'text-[#C6CCDD]'}`}>{props.rightName}</div>
        </div>
        </div>
    );
};

export default Toggle;
