import React, { useState } from 'react';
import expandIcon from '../../img/expand_line.png';
import shrinkIcon from '../../img/shrink_line.png';
import closeIcon from '../../img/close_icon.png';

interface PositionLineProps {
  symbol: string;
  entryPrice: number;
  side: string;
  size: number;
  liquidationPrice: number;
  unrealizedProfitLoss: number;
  unrealizedProfitLossPercent: number;
  tg:any;
  handleClosePosition: (symbol: string) => void;
}


const PositionLine: React.FC<PositionLineProps> = ({ symbol, entryPrice, side, size, handleClosePosition,liquidationPrice,unrealizedProfitLoss,tg,unrealizedProfitLossPercent }) => {
  
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div className={`text-white w-full max-w-2xl flex flex-col justify-between duration-500 ${isExpanded ? "h-auto" : "h-12"} rounded-md bg-gray-800 items-start`}>
  <div className='w-full max-w-2xl flex justify-between items-center px-4 py-2'> 
    <span>{symbol}</span>
    <span className='ml-2'>{entryPrice.toFixed(4).toString().replace(/\.?0*$/, '')}</span>
    <span className={`w-2 ml-4 font-semibold ${side === "BUY" ? "text-green-500" : "text-red-500"}`}>{size}</span>
    <div className='act_buttons flex flex-wrap w-[74px] justify-between'>
      <button
        className={`${side === "BUY" ? "bg-red-500" : "bg-green-500"} text-white flex items-center justify-center font-bold w-8 h-8 rounded focus:outline-none focus:shadow-outline`}
        onClick={() => {
          handleClosePosition(symbol)
          tg?.HapticFeedback?.impactOccurred('medium');
        }}
      >
        <img className='w-6' src={closeIcon} alt="close" />
      </button>
      <button className="h-8 duration-500 w-8 flex items-center justify-center  rounded focus:outline-none focus:shadow-outline bg-[#0063C8]" onClick={() => setIsExpanded(!isExpanded)} >
        {isExpanded
        ?<img className='w-6' src={shrinkIcon} alt="expand" />
        :<img className='w-6' src={expandIcon} alt="expand" />}
        
      </button>
    </div>
  </div>
  <div className={`${isExpanded ? "opacity-100 pb-2 max-h-[100px]" : "opacity-0 max-h-0"} overflow-hidden transition-opacity transition-max-h duration-200 flex font-semibold justify-start ml-4 flex-wrap`}>
    <span className='w-[80vw]'>liquidation price:<span className={`ml-2 w-2 font-semibold"}`}>{liquidationPrice.toFixed(2)} USDT</span></span>
    <span className='w-[80vw] mt-1'>unrealized P&L:<span className={` ml-2 w-2 font-semibold ${unrealizedProfitLoss>=0 ? "text-green-500" : "text-red-500"}`}>{Math.abs(unrealizedProfitLoss).toFixed(2)} USDT ({unrealizedProfitLossPercent.toFixed(2)}%)</span></span>
  </div>
</div>

  );
};

export default PositionLine;
