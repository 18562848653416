import React, { useEffect, useState } from 'react';
import './App.css';
import { Autocomplete, TextField,Paper} from '@mui/material';
import Header from './components/Header';
import axios from 'axios';
import Toggle from './components/Toggle';
import LeverageInput from './components/LeverageInput';
import Input from './components/Input';
import PriceLabel from './components/PriceLabel';
import { useTelegram } from './hooks/useTelegram';
import { HmacSHA384 } from 'crypto-js';
import  StocksChart  from './components/StocksChart';
import maxLeverages from './data/maxLeverages';
import debounce from './api/functions/deounce';
import Orders from './components/Orders';







function App() {
    const [options,setOptions]= useState([])

    const [symbol,setSymbol] = useState<string>("");
    const [symbolsList,setSymbolsList] = useState([])
    const [isLimit,setIsLimit] = useState(false);
    const [isCross,setIsCross] = useState(true);
    const [showChart, setShowChart] = useState(true);
    const [leverage,setLeverage] = useState<number>(1);
    const [maxLeverage,setMaxLeverage] = useState(100);
    const [price,setPrice] = useState();
    const [tf,setTf] = useState(30);

    const [priceInput,setPriceInput] = useState<number>()
    const [sizeInput,setSizeInput] = useState()

    const [walletName,setWalletName] = useState("");
    const [walletBalance,setWalletBalance] = useState<number>(0);

    const {tg} = useTelegram();
    const [apiKey,setApiKey] = useState('')
    const [secretKey,setSecretKey] = useState('')
    


  //SYMBOLS GETTING
  useEffect(()=>{    
    axios.get(`https://webdeving.net/api/futures/api/v2.1/market_summary`)
    .then(response => {
        console.log(response.data);
        setSymbolsList(response.data);
        setOptions(response.data.map((e:any)=>e.symbol));

    })
    .catch(error => {
        console.error('Error fetching market summary:', error);
    });

  },[])



  // LEVERAGE GETTING
  useEffect(()=>{    

    if(symbol){
      const requestNonce = Date.now().toString();
      const signature = generateSign('/api/v2.1/leverage',requestNonce,``);

      axios.get(`https://webdeving.net/api/futures/api/v2.1/leverage?symbol=${encodeURIComponent(symbol)}`,{headers:{
        'request-api': apiKey, 
        'request-sign': signature,        
        'request-nonce': requestNonce 
      }}
      )
      .then(response => {
          console.log(response.data);
          setLeverage(response.data.leverage);
      })
      .catch(error => {
          console.error('Error GET leverage:', error);
      });
    }
  },[symbol])


  // LEVERAGE SETTING
  useEffect(() => {

    const postLeverage = () => {
      if (leverage!==undefined && symbol && !isCross) {
        const requestNonce = Date.now().toString();
        const bodyStr = JSON.stringify({ 'symbol': symbol, 'leverage': leverage });
        const signature = generateSign('/api/v2.1/leverage', requestNonce, bodyStr);
  
        axios.post(`https://webdeving.net/api/futures/api/v2.1/leverage`, bodyStr, {
          headers: {
            'Content-Type': 'application/json', 
            'request-api': apiKey,
            'request-sign': signature,
            'request-nonce': requestNonce
          }
        })
        .then(response => console.log(response))
        .catch(error => console.log(error));
      }else{
        if (leverage!==undefined && symbol && isCross){
          const requestNonce = Date.now().toString();
          const bodyStr = JSON.stringify({ 'symbol': symbol, 'leverage': 0 });
          const signature = generateSign('/api/v2.1/leverage', requestNonce, bodyStr);
    
          axios.post(`https://webdeving.net/api/futures/api/v2.1/leverage`, bodyStr, {
            headers: {
              'Content-Type': 'application/json', 
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce
            }
          })
          .then(response => console.log(response))
          .catch(error => console.log(error));
        }
    }
  }

    const debouncedPostLeverage = debounce(postLeverage, 1000); // 1000 ms delay
  
    debouncedPostLeverage();
  }, [leverage, isCross, symbol]);
  

  //ORDER CREATING
  const createOrder = (isLong:boolean) =>{
    if ( (isLimit && priceInput  && sizeInput && symbol) || (!isLimit  && sizeInput && symbol) ){
      const requestNonce = Date.now().toString();
      var bodyStr;      
        //@ts-ignore      
      const orderSize = Math.floor(sizeInput/(price*((symbolsList.filter((el)=> el.symbol===symbol)[0].contractSize)||1)));
      console.log(orderSize,requestNonce);
      isLimit
      ?bodyStr = JSON.stringify({
        'symbol': symbol,
        'price':priceInput,
        'size':orderSize,
        'side':isLong?'BUY':'SELL',
        'type':'LIMIT'
      })
      :bodyStr = JSON.stringify({
        'symbol': symbol,
        'size':orderSize,
        'side':isLong?'BUY':'SELL',
        'type':'MARKET'
        
      });
      const signature = generateSign('/api/v2.1/order', requestNonce, bodyStr);

      axios.post(`https://webdeving.net/api/futures/api/v2.1/order`, bodyStr, {
        headers: {
          'Content-Type': 'application/json', // Specify that you're sending JSON
          'request-api': apiKey,
          'request-sign': signature,
          'request-nonce': requestNonce
        }
      })
      .then(function (response) {
        console.log(response);
        const status = response.data.status;
        if (status===15 || status===16 || status===17 || status===8 || status===101 || status===305){
          tg.showAlert(`Failed to create order!\n${JSON.parse(response.data.message)?.default_msg}`);
        }else{
          tg.showAlert("Order  created");
        }

      })
      .catch(function (error) {
        console.log(error);
        const errorMessage = error.response.data.message;
        tg.showAlert(`Failed to create order!\n${errorMessage}`);

      });
    }
  }

  //BALANCE GETTING
  useEffect(()=>{
      const requestNonce = Date.now().toString();
      const signature = generateSign('/api/v2.1/user/wallet',requestNonce,'');
      axios.get(`https://webdeving.net/api/futures/api/v2.1/user/wallet?wallet=${isCross?('CROSS@'):('ISOLATED@'+symbol+'-USD')}`,{headers:{
        'request-api': apiKey, 
        'request-sign': signature,
        'request-nonce': requestNonce 
      }}
      )
      .then((response) => {
          setWalletName(response.data[0].wallet.split('-')[0]);
          setWalletBalance(Math.trunc(response.data[0].availableBalance * 100) / 100);
      })
      .catch(error => {
        console.error('Error config:', error);
    });
    }
,[symbol,isCross])


  const handleCreateOrder = (isLong:boolean) =>{
    if(((isLimit && priceInput  && sizeInput && symbol) || (!isLimit  && sizeInput && symbol) )&& sizeInput>0){
      const message = `Are you sure you want to open a ${isLimit?"LIMIT":"MARKET"} ${isLong?"LONG":"SHORT"} position for ${sizeInput} USDT in ${symbol} futures with leverage of ${isCross?maxLeverage:leverage}?`;
      const callbackFunction = (isOkPressed:boolean) =>{
      if (isOkPressed){
        createOrder(isLong);
        }
    }
   tg.showConfirm(message,callbackFunction)

    }else{
      tg.showAlert("Please fill all fields");
    }
    
  }



  const generateSign = (path:string,requestNonce:string,bodyStr:string):string => {
    const message = path + requestNonce + bodyStr; 
    const signature = HmacSHA384(message, secretKey).toString();
    return signature;
  }

  useEffect(()=>{
    if (isCross){
      setLeverage(0);
    }else{
      setLeverage(5);
    }
  },[isCross])

  useEffect(()=>{

      setMaxLeverage(maxLeverages.find((item) => item.asset === symbol)?.maxLeverage || 20)
    
  },[symbol])


  useEffect(()=>{
    tg.ready();
    tg.MainButton.hide();
    tg.expand()

   },[tg])


   
   useEffect(()=>{
    if (tg){
      tg.CloudStorage.getItem('api_key', (error:any, retrievedValue:any) => {
        if (error) {
            console.error('Failed to retrieve value:', error);
        } else {
            setApiKey(retrievedValue);
        }
    });
    tg.CloudStorage.getItem('secret_key', (error:any, retrievedValue:any) => {
        if (error) {
            console.error('Failed to retrieve value:', error);
        } else {
            setSecretKey(retrievedValue);
        }
    });
    tg.CloudStorage.getItem('symbol', (error:any, retrievedValue:any) => {
      if (error) {
          console.error('Failed to retrieve value:', error);
      } else {
          setSymbol(retrievedValue);
      }
  });
    }
   },[tg])

  return (
      <div className="App bg-[#151B28]  flex flex-wrap justify-center content-start select-none web min-h-screen">
      <Header showWallet showOrders />

      <section className='symbol-and-price flex flex-wrap justify-around w-[90vw] mt-7'>
        <div className="autocompleteBlock `w-[150px] h-[71px]">
        <p className='text-[#37456C]'>symbol</p>
        <Autocomplete
  className='w-[150px]'
  autoComplete={true}
  value={symbol} // Set the value prop to your state variable
  disablePortal
  disableClearable
  clearOnEscape
  options={options}
  onChange={(event, newValue) => {
    setSymbol(newValue); // Update your state variable when the value changes
    tg.CloudStorage.setItem('symbol', newValue, (error:any, success:any) => {
      if (error) {
        console.error('Failed to set value:', error);
      } else {
        // Close the keyboard by blurring the input field
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }
    });
  }}
  renderInput={(params) => <TextField {...params} />}
  PaperComponent={({ children }) => (
    <Paper style={{ backgroundColor: '#223056',color:'#93A5D4' }}>{children}</Paper>
  )}
  sx={{
    '& .MuiAutocomplete-inputRoot': {
      color: '#93A5D4',
      backgroundColor: '#223056'
    },
    '& .MuiAutocomplete-inputRoot.Mui-focused': {
      // borderColor: '#37456C'
    },
  }}
/>

        </div>
        
        <PriceLabel symbol={symbol} setPrice={setPrice}/>
      </section>


      <div className={showChart ? "visibleChart" : "hiddenChart"}>
        {showChart && <StocksChart symbol={symbol} tf={tf} />}
      </div>



      <div className=" flex justify-around items-center  mt-2 h-[75px] w-[90vw]">
        {/* <Toggle label="margin mode" leftName='cross' rightName='isolated' state={isCross} setState={setIsCross} /> */}
        {showChart
        ?<div className='flex justify-between  w-[150px] duration-500'>
        <button className='w-[100px] h-[41px] duration-500 mt-9 bg-[#223056] text-[#93A5D4] rounded-md' onClick={()=>setShowChart(!showChart)}> {showChart?'hide chart' :'show chart'} </button>
        <button className='w-[41px] h-[41px] mt-9 bg-[#223056] text-[#93A5D4] rounded-md' onClick={()=>{if(tf===30){setTf(1440)}else{if(tf===1440){setTf(1)}else{setTf(30)}}}}> {tf===30?'30m':tf===1440?'1d':'1m'} </button>
        </div>
        :<div className='flex justify-between w-[150px] '>
        <button className='w-[150px] duration-500 h-[41px] mt-9 bg-[#223056] text-[#93A5D4] rounded-md' onClick={()=>setShowChart(!showChart)}> {showChart?'hide chart' :'show chart'} </button>
        </div>}
      </div>




      <section className='flex justify-around w-[90vw] mt-5'>
        
      

      <div className="left w-[45vw] flex justify-center flex-wrap">
        <Toggle label="order type" leftName='limit' rightName='market' state={isLimit} setState={setIsLimit} />
        <Input value={sizeInput} setValue={setSizeInput} className="mt-5" label="size (USDT)"/>
      </div>

      <div className="right w-[45vw] flex justify-center items-start flex-wrap">
        <LeverageInput  onValueChange={setLeverage} value={leverage} min={1} max={maxLeverage} isCross={isCross} />
            {isLimit
            ?<Input type="number" value={priceInput} setValue={setPriceInput} className="mt-5" label="price"/>
            :<div className={`w-[150px] h-[71px] mt-5`}>
            <p className='text-[#37456C] '>price</p>
            <p className={`w-[150px] h-[40px]  text-[#93A5D4] flex items-center bg-[#191f32] rounded-md justify-around text-2xl`}>{price}</p>
            </div>}
      </div>
      </section>
      <section className='flex justify-around w-[90vw] mt-3'>
      <div className='flex justify-around w-[90vw] text-md text-[#93A5D4]'>
              {walletName
              ?<p> Available balance –{' '+walletBalance+" USDT"}</p>
              :<p></p>
              }
      </div>
      </section>
      <section className='flex justify-around w-[90vw] mt-2'>
      <div className='flex justify-around w-[90vw]'>
        <button onClick={()=>{handleCreateOrder(true);tg?.HapticFeedback?.impactOccurred('medium');}} className={` outline-1 w-[150px] h-[30px] rounded bg-[#27AF2D] text-white`}>BUY</button>
        <button onClick={()=>{handleCreateOrder(false);tg?.HapticFeedback?.impactOccurred('medium');}} className={` outline-1 w-[150px] h-[30px] rounded bg-[#AF2727] text-white`}>SELL</button>
      </div>
      </section>
      <section className='flex justify-around w-[90vw] mt-3'>
              <Orders tg={tg} apiKey={apiKey} secretKey={secretKey} />
      </section>
              

</div>
  );
}

export default App;
