import React, { useEffect, useState } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import Header from './Header';
import Input from './Input';
import { useNavigate } from 'react-router-dom';

const Authorization = () => {
    const [apiValue, setApiValue] = useState('');
    const [secretValue, setSecretValue] = useState('');
    const { tg } = useTelegram();
    const navigate = useNavigate();

    const handleSave = () => {
        if (tg && tg.CloudStorage && tg.CloudStorage.setItem) {
            tg.CloudStorage.setItem('api_key', apiValue, (error:any, success:any) => {
                if (error) {
                    console.error('Failed to set value:', error);
                }else{
                    tg.CloudStorage.setItem('secret_key', secretValue, (error:any, success:any) => {
                        if (error) {
                            console.error('Failed to set value:', error);
                        }else{
                            tg.MainButton.hide();
                            navigate('/');
                        }
                    });
                }
            });
        }
    };

    const handleDelete = () => {
        if (tg && tg.CloudStorage && tg.CloudStorage.setItem) {
            tg.CloudStorage.removeItem('api_key');
            tg.CloudStorage.removeItem('secret_key');
            navigate('/')
        }
    };

    

    useEffect(()=>{
        if(apiValue.length>0 && secretValue.length>0){
            tg?.MainButton.show();
        }else{
            tg?.MainButton.hide();
        }
    },[apiValue,secretValue])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', handleSave)
        return () => {
            tg.offEvent('mainButtonClicked', handleSave)
        }
    }, [handleSave])



    useEffect(() => {
        if (tg && tg.CloudStorage && tg.CloudStorage.getItem) {
            tg.CloudStorage.getItem('api_key', (error:any, retrievedValue:any) => {
                if (error) {
                    console.error('Failed to retrieve value:', error);
                } else {
                    setApiValue(retrievedValue);
                }
            });
            tg.CloudStorage.getItem('secret_key', (error:any, retrievedValue:any) => {
                if (error) {
                    console.error('Failed to retrieve value:', error);
                } else {
                    setSecretValue(retrievedValue);
                }
            });
        }
    }, [tg]);

    useEffect(()=>{
        tg?.MainButton.setParams({color:'#275DDD',text:'CONTINUE',text_color:'#FFFFFF'});
        tg.MainButton.enable();
        tg.MainButton.show();
    },[]);
    


    return (
        <div className='bg-[#151B28] h-[100vh] w-[100vw] flex flex-wrap justify-center content-start'>
            <Header showSettingsButton={false} showBackButton={true}/>
            <div className='text-xl w-[60vw] text-cyan-50 text-center mt-3'>
            </div>
            <div className='w-[50vw] flex justify-center flex-wrap'>
            <Input className='mt-3' label="api key" setValue={setApiValue} value={apiValue} />
            <Input className='mt-3' label="secret key" setValue={setSecretValue} value={secretValue} />
            <button onClick={()=>handleDelete()} className='bg-red-600 w-[150px] mt-5 rounded text-stone-200'>delete keys</button>
            </div>
        </div>
    );
};

export default Authorization;
