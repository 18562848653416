import React, { useEffect, useState } from 'react';
import Header from './Header';
import { HmacSHA384 } from 'crypto-js';
import axios from 'axios';
import { useTelegram } from '../hooks/useTelegram';
import { Autocomplete,TextField } from '@mui/material';
import Input from './Input';

const Wallet = () => {

    const [apiKey,setApiKey] = useState('')
    const [secretKey,setSecretKey] = useState('') 
    const {tg}= useTelegram();
    const [wallets,setWallets] =useState([])
    const [srcWallet,setSrcWallet] = useState<any>('');
    const [destWallet,setDestWallet] = useState<any>('');
    const [amount, setAmount] = useState(''); 

    const generateSign = (path:string,requestNonce:string,bodyStr:string):string => {
      const message = path + requestNonce + bodyStr; 
      const signature = HmacSHA384(message, secretKey).toString();
      return signature;
    }

    useEffect(()=>{
      if (apiKey&&secretKey){
          const requestNonce = Date.now().toString();
          const bodyStr='';
          const signature = generateSign('/api/v2.1/user/wallet', requestNonce, bodyStr);
    
          axios.get(`https://webdeving.net/api/futures/api/v2.1/user/wallet`, {
            headers: {
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce
            }
          })
          .then(function (response) {
            console.log(response);
            setWallets(response.data);
          })
          .catch(function (error:any) {
            console.log(error,secretKey,apiKey);
          });
        
      }
    },[apiKey,secretKey])

      const getWalletType = (walletName:any) => {
        if (walletName && walletName.includes('@')) {
          return walletName.split('@')[0] + '@'; 
        }
        return ''; 
      };
      

      const handleTransfer = async () => {
      
        // Setup transfer body
        const transferBody = {
          walletSrc: srcWallet.wallet.split('@')[1].split('-')[0], 
          walletSrcType: srcWallet.wallet.split('@')[0],
          walletDest: destWallet.wallet.split('@')[1].split('-')[0],
          walletDestType: destWallet.wallet.split('@')[0], 
          apiWallets: [
            {
              "currency": "USD",
              "allBalance": false,
              "amount":-parseFloat(amount)
            },
            
          ]
        };

        console.log(transferBody);
        
      
        // Generate request nonce and signature
        const requestNonce = Date.now().toString();
        const bodyStr = JSON.stringify(transferBody); // The body should be a string when used for signing
        const signature = generateSign('/api/v2.1/user/wallet/transfer', requestNonce, bodyStr);
      
        // Make the API call to perform the transfer
        try {
          const response = await axios.post(`https://webdeving.net/api/futures/api/v2.1/user/wallet/transfer`, transferBody, {
            headers: {
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce,
              'Content-Type': 'application/json' // Ensure the content type is set to JSON
            }
          });
      
          // Handle success
          console.log('Transfer successful', response.data);
        } catch (error) {
          // Handle error
          console.error('Transfer failed');
        }
      };
      
      

    useEffect(() => {
        tg.onEvent('mainButtonClicked', handleTransfer)
        return () => {
            tg.offEvent('mainButtonClicked', handleTransfer)
        }
    }, [handleTransfer,amount,destWallet,srcWallet])

    useEffect(()=>{
        if(destWallet && srcWallet  && amount){
          // tg?.MainButton.setParams({color:'#275DDD',text:'TRANSFER FUNDS',text_color:'#FFFFFF'});
          // tg?.MainButton.enable();
        }else{
          tg?.MainButton.setParams({color:'#555555',text:'WALLET TRANSFERS NOT SUPPORTED NOW',text_color:'#FFFFFF'});
          tg?.MainButton.disable();
        }
      },[destWallet,srcWallet,amount])

      useEffect(()=>{
        tg.CloudStorage.getItem('api_key', (error:any, retrievedValue:any) => {
          if (error) {
              console.error('Failed to retrieve value:', error);
          } else {
              setApiKey(retrievedValue);
          }
      });
        tg.CloudStorage.getItem('secret_key', (error:any, retrievedValue:any) => {
            if (error) {
                console.error('Failed to retrieve value:', error);
            } else {
                setSecretKey(retrievedValue);
            }
        });
      },[tg])

    const getOptionLabel = (option:any) => {
    let label = `${option.wallet} - ${option.totalValue}`;
    label=label.replace('ISOLATED', 'ISL').replace('USD','');

    return label;
    };



    return (
        <div className='bg-[#151B28] h-[100vh] w-[100vw] flex flex-wrap justify-center content-start'>
            <Header showBackButton/>
            
            <div className='flex justify-around mt-3 w-[90vw]'>
            <div className="autocompleteBlock `w-[150px] h-[71px]">
            <p className='text-[#37456C]'>source wallet</p>
            <Autocomplete
            className='w-[150px]'
            autoComplete={true}
            disablePortal
            disableClearable
            clearIcon={false}
            options={wallets.filter((wallet:any) => wallet.totalValue !==0)}
            onChange={(event, newValue) => {
              setSrcWallet(newValue)
            }}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                color:'#93A5D4',
                backgroundColor:'#223056'
              },
              '& .MuiAutocomplete-inputRoot.Mui-focused': {
                // borderColor:'#37456C'
              },
            }}
          />
          </div>
          <div className="autocompleteBlock `w-[150px] h-[71px]">
          <p className='text-[#37456C]'>destination wallet</p>
          <Autocomplete
            className='w-[150px]'
            autoComplete={true}
            disablePortal
            disableClearable
            clearIcon={false}
            options={wallets}
            onChange={(event, newValue) => {
              setDestWallet(newValue)
            }}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                color:'#93A5D4',
                backgroundColor:'#223056'
              },
              '& .MuiAutocomplete-inputRoot.Mui-focused': {
                // borderColor:'#37456C'
              },
            }}
          />
          </div>
            </div>
        <Input label='amount' value={amount} setValue={setAmount} className='mt-3'/>
        </div>
        
    );
};

export default Wallet;