import React from 'react';
import PropTypes from 'prop-types'; // Don't forget to install prop-types if you haven't
import logo from '../img/btse2.png';
import settIcon from '../img/setti.png';
import backIcon from '../img/back.png'
import walletIcon from '../img/wallet.png'
import ordersIcon from '../img/order.png'
import { useNavigate } from 'react-router-dom';

const Header = ({ showSettingsButton = true,showBackButton=false,showWallet=false,showOrders=false}) => {
    const navigate = useNavigate();

    return (
        <div className='h-12 flex justify-center items-center w-[100vw] rounded-sm bg-[#0063C8] relative'>
            {showBackButton && <img onClick={()=>navigate('/app')} className='w-7 absolute left-3' src={backIcon} alt="back" />}
            <img className='w-[23%]' src={logo} alt="btse" />
            {/* {showOrders && <img onClick={()=>navigate('/orders')} className='w-7 absolute right-[50px]' src={ordersIcon} alt="orders" />} */}
            {/* {showWallet && <img onClick={()=>navigate('/wallet')} className='w-7 absolute right-[50px]' src={walletIcon} alt="wallet" />} */}
            {showSettingsButton && <img onClick={()=>navigate('/settings')} className='w-7 absolute right-3' src={settIcon} alt="settings" />}
        </div>
    );
};

Header.propTypes = {
    showSettingsIcon: PropTypes.bool
};

export default Header;
