import React,{useEffect,useState,useRef} from 'react';
import axios from 'axios';


const PriceLabel = ({...props}) => {

    const [price, setPrice] = useState<number>(0);
    const [prevPrice, setPrevPrice] = useState<number | null>(null);
    const priceRef = useRef<number>(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [priceColor, setPriceColor] = useState<string>('text-[#93A5D4]'); // Default color


    useEffect(() => {
        async function fetchPrice() {
            if (props.symbol) {
                try {
                    const response = await axios.get(`https://webdeving.net/api/futures/api/v2.1/market_summary?symbol=${props.symbol}`);
                    
                    // Set prevPrice using the current value from the ref
                    setPrevPrice(priceRef.current);
                    
                    // Update price and the value in the ref
                    const newPrice = response.data[0].last;
                    determineColor(priceRef.current, newPrice); // Update the color based on the current and new price
                    setPrice(newPrice);
                    priceRef.current = newPrice;
                    props.setPrice(newPrice);

                } catch (error: any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        }
        
        fetchPrice();
        
        const intervalId = setInterval(fetchPrice, 2000); 
        return () => clearInterval(intervalId);
    }, [props.symbol]);

    
    // New useEffect to update prevPrice when price changes

    
    // setInterval(()=>{
    //     let R = Math.random();
    //     setPrevPrice(price);
    //     setPrice(Math.round(R*1000%10));
    // },3000)
    

    const determineColor = (currentPrice: number, nextPrice: number | null) => {
        if (nextPrice === null) {
            return;
        }
    
        if (nextPrice > currentPrice) {
            setPriceColor('text-green-500');
        } else if (nextPrice < currentPrice) {
            setPriceColor('text-red-500');
        }
        // If nextPrice === currentPrice, we don't update the color
    };
    
    
    

    return (
        <div className='current_price w-[160px] flex justify-center items-center content-center'>
            {props.symbol&&price
            ?<p className={`${priceColor} duration-300 text-3xl mt-8`}>${price}</p>
            :<p className={`${priceColor} duration-300 text-3xl mt-8`}></p>}
        </div>
    );
    
};

export default PriceLabel;