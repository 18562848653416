// @ts-nocheck


import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  
);

const getFormattedTimestamp = (unixTimestamp, tf) => {
  const date = new Date(unixTimestamp * 1000);
  let formattedTimestamp;

  if (tf === 1440) {
    // Format for just the date in "08 Dec" format
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); // This will give the abbreviated month name
    formattedTimestamp = `${day.toString().padStart(2, '0')} ${month}`;
  } else {
    // Format for time in "HH:MM" format
    const hours = date.getHours();
    const minutes = date.getMinutes();
    formattedTimestamp = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  return formattedTimestamp;
};



// Define the plugin
const chartAreaBackground = {
    id: 'chartAreaBackground',
    beforeDraw(chart) {
      const ctx = chart.canvas.getContext('2d');
      ctx.save();
      ctx.fillStyle = '#13161E'; // Set to your desired background color
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };
  
  // Add the plugin to your ChartJS configuration
  ChartJS.register(chartAreaBackground);
  
  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1, // Adjust this value as needed for your layout
    layout: {
      padding: {
        top: 10,
        right: 15,
        bottom: 10,
        left: 0
      },
    },
    scales: {
      x: {
        // ... your x-axis configuration
        ticks: {
          font: {
            size: 10 // Larger font for mobile readability
          },
          autoSkip: true,
          maxTicksLimit: 5 // Adjust number of ticks for smaller screens
        }
      },
      y: {
        // ... your y-axis configuration
        ticks: {
          font: {
            size: 10 // Larger font for mobile readability
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false, // This will hide the legend
      },
      title: {
        display: false, // This will hide the title
      },  
      tooltip: {
        // ... your tooltip configuration
        bodyFont: {
          size: 14 // Larger font for mobile readability
        }
      }
    },
    // ... other configuration options
  };
  

const StocksChart = ({symbol,tf}) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        borderColor: '#2196F3',
        smooth:true,
        updateMode:"hide",
      },
    ],
    options
    
  });

  

  

  // Dummy function to simulate new incoming data. Replace with your actual data source.
  const fetchNewData = async () => {
  
    const response =await axios.get(`https://webdeving.net/api/futures/api/v2.1/ohlcv?symbol=${symbol}&resolution=${tf}`);
    
    const chartData = response.data;

    console.log(response.data)

    return chartData;

  };


  const fetchInitialData = async () => {
    try {
      
      const response = await axios.get(`https://webdeving.net/api/futures/api/v2.1/ohlcv?symbol=${symbol}&resolution=${tf}`);
      const data: DataPoint[] = response.data;
  
      // Assuming the API returns an array of data points, and you need the last 50
      const lastFiftyDataPoints = data.slice(0,100).reverse(); 
  
      return lastFiftyDataPoints.map((dp: DataPoint) => ({
        timestamp: getFormattedTimestamp(dp[0], tf),
        price: dp[4]
      }));
    } catch (error) {
      console.error('Error fetching initial data:', error);
      return [];
    }
  };
  
//   useEffect( ()=>{
//     async function update() {
//     await fetchInitialData()
//   }
//   update()
// }
//   ,[symbol])



  useEffect(() => {
    if (symbol){
      // Load initial data
    fetchInitialData().then(initialData => {
      setChartData({
        labels: initialData.map(dp => dp.timestamp),
        datasets: [{
          label: 'Stock Price',
          data: initialData.map(dp => dp.price),
          fill: false,
          borderColor: '#2196F3',
          pointRadius: 0, // Add this line to remove point markers
          smooth: true,
          updateMode: "hide"
        }]
      });
    });
    
  
    const interval = setInterval(() => {

      
      fetchNewData().then(newData => {
        if (newData && newData.length > 0) {
          const latestData = newData[0]; // Assuming the latest data is the last element
          const newTimestamp = getFormattedTimestamp(latestData[0], tf);

          
          const newPrice = latestData[4];
  
          setChartData(prevData => {
            const newLabels = [...prevData.labels, newTimestamp];
            const newDataPoints = [...prevData.datasets[0].data, newPrice];
  
            // Remove the oldest data point if we exceed 50 points
            if (newLabels.length > 50) {
              newLabels.shift();
              newDataPoints.shift();
            }
  
            return {
              labels: newLabels,
              datasets: [{ ...prevData.datasets[0], data: newDataPoints }]
            };
          });
        }
      }).catch(error => {
        console.error('Error fetching new data:', error);
      });
    }, tf*60000); 
  
    return () => clearInterval(interval);
    }
  }, [symbol,tf]);
  

  return(
    <div className='w-[94vw] h-[40vh] mt-5'>
     <Line options={{ responsive: true, maintainAspectRatio: false }} data={chartData} />
       </div>
  )}

export default StocksChart;