import React, { useEffect, useState } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import Header from './Header';
import Toggle from './Toggle';
import { HmacSHA384 } from 'crypto-js';
import axios from 'axios';
import PositionLine from './Orders/PositionLine';
import reloadIcon from '../img/reload_icon.png';

const Orders:React.FC<{apiKey:string,secretKey:string,tg:any}> = ({apiKey,secretKey,tg}) => {
    const [isPositions,setIsPositions] = useState(true)
    const [positions, setPositions] = useState([]);
    const [openOrders, setOpenOrders] = useState([]);



    
  const generateSign = (path:string,requestNonce:string,bodyStr:string):string => {
    const message = path + requestNonce + bodyStr; 
    const signature = HmacSHA384(message, secretKey).toString();
    return signature;
  }

    const getPositions = async () => {
        const requestNonce = Date.now().toString();
        const signature = generateSign('/api/v2.1/user/positions', requestNonce, '');
      
        try {
          const response = await axios.get(`https://webdeving.net/api/futures/api/v2.1/user/positions`, {
            headers: {
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce,
            }
          });
      
          setPositions(response.data);
        } catch (error) {
          console.error('Failed to fetch positions:', error);
        }
      };
      
      const getOpenOrders = async () => {
        const requestNonce = Date.now().toString();
        const signature = generateSign('/api/v2.1/user/open_orders', requestNonce, '');
      
        try {
          const response = await axios.get(`https://webdeving.net/api/futures/api/v2.1/user/open_orders`, {
            headers: {
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce,
            }
          });
      
          setOpenOrders(response.data);
        } catch (error) {
          console.error('Failed to fetch open orders:', error);
        }
      };

      useEffect(()=>{
        if (apiKey && secretKey) {
          getPositions();
          getOpenOrders();
        }
      },[apiKey,secretKey])

      useEffect(() => {
        
        const interval = setInterval(() => {
          if (isPositions === true && apiKey && secretKey) {
            getPositions();
          } else if (isPositions === false && apiKey && secretKey) {
            getOpenOrders();
          }
        }, 4000);
      

        return () => clearInterval(interval);
      }, [isPositions, apiKey, secretKey]);
      

      const closePosition = (symbol:string) =>{
       
          const requestNonce = Date.now().toString();
          var bodyStr= JSON.stringify({
            'symbol':symbol,
            'type':'MARKET'
          })
          const signature = generateSign('/api/v2.1/order/close_position', requestNonce, bodyStr);
    
          axios.post(`https://webdeving.net/api/futures/api/v2.1/order/close_position`, bodyStr, {
            headers: {
              'Content-Type': 'application/json',
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce
            }
          })
          .then(function (response) {
            console.log(response);
            const status = response.data.status;
            if (status===15 || status===16 || status===17 || status===8){
              tg.showAlert(`Failed to close position!\n${JSON.parse(response.data.message)?.default_msg}`);
            }else{
              tg.showAlert("Position closed");
            }
            getPositions();
          })
          .catch(function (error) {
            console.log(error);
            const errorMessage = error.response.data.message;
            tg.showAlert(`Failed to close position!\n${errorMessage}`);
    
          });
        }
      

        const handleClosePosition = (symbol:string) =>{

            const message = `Are you sure you want to close your ${symbol} position with opposite market order?`;
            const callbackFunction = (isOkPressed:boolean) =>{
            if (isOkPressed){
              closePosition(symbol);
              }
          }
         tg.showConfirm(message,callbackFunction)
          
        }

        const cancelOrder = (symbol:string,orderId:string)=>{

          const requestNonce = Date.now().toString();
          var bodyStr= ({
            'symbol':symbol,
            'orderID':orderId
          })
          const signature = generateSign('/api/v2.1/order', requestNonce, '');
    
          


          axios.delete(`https://webdeving.net/api/futures/api/v2.1/order`, {
            headers: {
              'Content-Type': 'application/json',
              'request-api': apiKey,
              'request-sign': signature,
              'request-nonce': requestNonce
            },params:bodyStr})
          .then(function (response) {
            console.log(response);
            const status = response.data.status;
            if (status===15 || status===16 || status===17 || status===8){
              tg.showAlert(`Failed to cancel order!\n${JSON.parse(response.data.message)?.default_msg}`);
            }else{
              tg.showAlert("Order canceled");
            }
            getOpenOrders();
          })
          .catch(function (error) {
            console.log(error);
            const errorMessage = error.response.data.message;
            tg.showAlert(`Failed to cancel order!\n${errorMessage}`);
    
          });
        }


        const handleCancelOrder = (symbol:string,orderId:string,side:string) =>{

          const message = `Are you sure you want to cancel your ${symbol} ${side} order?`;
          const callbackFunction = (isOkPressed:boolean) =>{
          if (isOkPressed){
            cancelOrder(symbol,orderId);
            }
        }
       tg.showConfirm(message,callbackFunction)
        
      }
      
    


    return (
        <div className='bg-[#151B28] h-auto flex flex-wrap justify-center content-start select-none'>
            <Toggle leftName='positions' label="show" className='mt-2' rightName='orders' state={isPositions} setState={setIsPositions} />

            <div className='w-[85vw] flex justify-center mt-5 duration-300'>

              
      {isPositions === true && (
        <div className="flex flex-col items-center py-4 space-y-2">
            <div className="text-white w-[85vw]  flex justify-between items-center px-4 py-2 rounded-md bg-gray-700">
            <span className="font-semibold">symbol</span>
            <span className="font-semibold">entry price</span>
            <span className="font-semibold">size</span>
            <span className="font-semibold">ㅤ ㅤ ㅤ ㅤ </span>
            {/* <button className="h-8 w-8 flex items-center justify-center  rounded focus:outline-none focus:shadow-outline bg-[#0063C8]" onClick={() => getPositions()} >
              <img className='w-5' src={reloadIcon} alt="reload" />
            </button> */}
            </div>
            {positions.map((position:any) => (
              <PositionLine 
                tg={tg}
                key={position.positionId} 
                symbol={position.symbol} 
                entryPrice={position.entryPrice} 
                side={position.side} 
                size={position.size} 
                liquidationPrice={position.liquidationPrice}
                unrealizedProfitLoss={position.unrealizedProfitLoss}
                unrealizedProfitLossPercent={position.unrealizedProfitLoss / position.totalMaintenanceMargin * 100}
                handleClosePosition={() => handleClosePosition(position.symbol)}
              />
))}
        </div>
        )}

      {isPositions === false && (
          <div className="flex flex-col items-center space-y-2 py-4">
            <div className="text-white w-[85vw] max-w-2xl flex justify-between items-center px-4 py-2 rounded-md bg-gray-700">
            <span className="font-semibold">symbol</span>
            <span className="font-semibold">price</span>
            <span className="font-semibold">size</span>
            <span className="font-semibold">ㅤ ㅤ ㅤ ㅤ </span>
            {/* <button className="h-8 w-8 flex items-center justify-center  rounded focus:outline-none focus:shadow-outline bg-[#0063C8]" onClick={() => getOpenOrders()} >
              <img className='w-5' src={reloadIcon} alt="reload" />
            </button> */}
          </div>
          {openOrders.map((order:any) => (
          <div key={order.orderID} className="text-white w-full max-w-2xl flex justify-between items-center px-4 py-2 rounded-md bg-gray-800">
            <span>{order.symbol}</span>
            <span className='text-center '>{order.price.toFixed(4)}</span>
            <span className={` w-2 font-semibold ${order.side === "BUY" ? "text-green-500" : "text-red-500"}`}>{order.size}</span>
            <button
              className={`bg-[#0063C8]  text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline`}
              onClick={() => 
                {handleCancelOrder(order.symbol,order.orderID,order.side);
                tg?.HapticFeedback?.impactOccurred('medium');}
                }
            >
                        cancel
                      </button>
              </div>

  ))}
</div>

      )}
    </div>
  </div>
    );
};

export default Orders;