const maxLeverages = [
    {"asset": "1INCHPFC", "maxLeverage": 20},
    {"asset": "1KFLOKIPFC", "maxLeverage": 20},
    {"asset": "AAVEPFC", "maxLeverage": 20},
    {"asset": "ADAPFC", "maxLeverage": 50},
    {"asset": "ALGOPFC", "maxLeverage": 20},
    {"asset": "ALICEPFC", "maxLeverage": 20},
    {"asset": "APEPFC", "maxLeverage": 50},
    {"asset": "APTPFC", "maxLeverage": 20},
    {"asset": "ARBPFC", "maxLeverage": 20},
    {"asset": "ATOMPFC", "maxLeverage": 50},
    {"asset": "AVAXPFC", "maxLeverage": 50},
    {"asset": "AXSPFC", "maxLeverage": 20},
    {"asset": "BALPFC", "maxLeverage": 20},
    {"asset": "BANDPFC", "maxLeverage": 20},
    {"asset": "BATPFC", "maxLeverage": 20},
    {"asset": "BCHPFC", "maxLeverage": 20},
    {"asset": "BMBTCPFC", "maxLeverage": 100},
    {"asset": "BNBPFC", "maxLeverage": 50},
    {"asset": "BTCPFC", "maxLeverage": 100},
    {"asset": "C98PFC", "maxLeverage": 20},
    {"asset": "CELRPFC", "maxLeverage": 20},
    {"asset": "CHRPFC", "maxLeverage": 20},
    {"asset": "CHZPFC", "maxLeverage": 20},
    {"asset": "COMPPFC", "maxLeverage": 20},
    {"asset": "CRVPFC", "maxLeverage": 20},
    {"asset": "CTSIPFC", "maxLeverage": 20},
    {"asset": "DOGEPFC", "maxLeverage": 50},
    {"asset": "DOTPFC", "maxLeverage": 50},
    {"asset": "DYDXPFC", "maxLeverage": 20},
    {"asset": "EGLDPFC", "maxLeverage": 20},
    {"asset": "ENJPFC", "maxLeverage": 20},
    {"asset": "ENSPFC", "maxLeverage": 20},
    {"asset": "EOSPFC", "maxLeverage": 20},
    {"asset": "ETHPFC", "maxLeverage": 100},
    {"asset": "FILPFC", "maxLeverage": 20},
    {"asset": "FTMPFC", "maxLeverage": 20},
    {"asset": "GALAPFC", "maxLeverage": 20},
    {"asset": "GALPFC", "maxLeverage": 20},
    {"asset": "GMTPFC", "maxLeverage": 20},
    {"asset": "GRTPFC", "maxLeverage": 20},
    {"asset": "IOTXPFC", "maxLeverage": 20},
    {"asset": "KASPFC", "maxLeverage": 20},
    {"asset": "KLAYPFC", "maxLeverage": 20},
    {"asset": "KNCPFC", "maxLeverage": 20},
    {"asset": "KSMPFC", "maxLeverage": 20},
    {"asset": "LDOPFC", "maxLeverage": 20},
    {"asset": "LINKPFC", "maxLeverage": 50},
    {"asset": "LTCPFC", "maxLeverage": 50},
    {"asset": "MANAPFC", "maxLeverage": 20},
    {"asset": "MASKPFC", "maxLeverage": 20},
    {"asset": "MATICPFC", "maxLeverage": 50},
    {"asset": "MKRPFC", "maxLeverage": 20},
    {"asset": "NEARPFC", "maxLeverage": 50},
    {"asset": "OMGPFC", "maxLeverage": 20},
    {"asset": "ONEPFC", "maxLeverage": 20},
    {"asset": "OPPFC", "maxLeverage": 20},
    {"asset": "PEOPLEPFC", "maxLeverage": 20},
    {"asset": "PYTHPFC", "maxLeverage": 20},
    {"asset": "RUNEPFC", "maxLeverage": 20},
    {"asset": "SANDPFC", "maxLeverage": 20},
    {"asset": "SKLPFC", "maxLeverage": 20},
    {"asset": "SNXPFC", "maxLeverage": 20},
    {"asset": "SOLPFC", "maxLeverage": 20},
    {"asset": "STORJPFC", "maxLeverage": 20},
    {"asset": "SUSHIPFC", "maxLeverage": 20},
    {"asset": "TOKENPFC", "maxLeverage": 20},
    {"asset": "TRBPFC", "maxLeverage": 20},
    {"asset": "TRXPFC", "maxLeverage": 20},
    {"asset": "UNIPFC", "maxLeverage": 20},
    {"asset": "VICPFC", "maxLeverage": 20},
    {"asset": "XMRPFC", "maxLeverage": 20},
    {"asset": "XRPPFC", "maxLeverage": 50},
    {"asset": "XTZPFC", "maxLeverage": 20},
    {"asset": "YFIPFC", "maxLeverage": 20},
    {"asset": "ZILPFC", "maxLeverage": 20}
  ]
  
  export default maxLeverages;