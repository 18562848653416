import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Settings from './components/Settings';
import Authorization from './components/Authorization';
import Room from './components/Room';
import Wallet from './components/Wallet';
import Orders from './components/Orders';
import { AnimatePresence, motion, Variants, Transition } from 'framer-motion';


type Direction = -1 | 0 | 1;

// Animation variants for the pages
const pageVariants: Variants = {
  initial: (direction: Direction) => ({
    opacity: 0,
    scale: direction > 0 ? 0.8 : 1.2,
  }),
  in: {
    opacity: 1,
    scale: 1,
  },
  out: (direction: Direction) => ({
    opacity: 0,
    scale: direction > 0 ? 1.2 : 0.8,
  }),
};

const pageTransition: Transition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.5
};

// Assume direction is obtained somehow, e.g., from React context or state
const direction: Direction = 1;



const router = createBrowserRouter([
  {
    path: "/app",
    element: <App/>,
  },
  {
    path:"/auth",
    element:<Authorization/>
  },
  {
    path:"/",
    element:<Room/>
  },
  {
    path:"/settings",
    element:<Settings/>
  },
  {
    path:"/wallet",
    element:<Wallet/>
  },
  // {
  //   path:"/orders",
  //   element:<Orders/>
  // },

]);

// Wrap each Route's element in a motion.div with the appropriate variants
const routesWithAnimation = router.routes.map((route:any) => ({
  ...route,
  element: (
    <motion.div
      key={route.path}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      custom={direction} // Pass the direction as a custom prop for variants
      style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#111" }} // Add dark background color
    >
      {route.element}
    </motion.div>
  ),
}));

const animatedRouter = createBrowserRouter(routesWithAnimation);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AnimatePresence>
      <div style={{ backgroundColor: "#111" }}> {/* Set background color for the entire page */}
        <RouterProvider router={animatedRouter} />
      </div>
    </AnimatePresence>
  </React.StrictMode>
);
