import React from 'react';
import { useTelegram } from '../hooks/useTelegram';

interface LeverageInputProps {
  value: number; // Now this component receives value directly from the parent
  onValueChange: (value: number) => void; // Callback to inform the parent about changes
  className?: string;
  min?: number;
  max?: number;
  isCross:boolean;
}

const LeverageInput: React.FC<LeverageInputProps> = ({
  value, 
  onValueChange, 
  className = "", 
  min = 1,     // Default minimum value
  max = 100,   // Default maximum value
  isCross
}) => {
  const {tg} = useTelegram();

  const handleIncrement = () => {
    const newValue = Math.min(value + 1, max);
    onValueChange(newValue); // Inform parent of the new value
    tg?.HapticFeedback?.selectionChanged();
  };
  
  const handleDecrement = () => {
    const newValue = Math.max(value - 1, min);
    onValueChange(newValue); // Inform parent of the new value
    tg?.HapticFeedback?.selectionChanged();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue === "") {
      onValueChange(min); // If input is empty, revert to min value
    } else {
      const numericValue = parseInt(inputValue, 10);
      if (numericValue >= min && numericValue <= max) {
        onValueChange(numericValue); // Inform parent of the new value
      }
    }
  };

  return (
    <div className={`w-[150px] h-[71px]  ${className}`}>
        <p className='text-[#37456C]'>leverage</p>
        {isCross
        ?<div className={`w-[150px] h-[60px] bg-[#223056] opacity-50  rounded-md flex items-center justify-around `}>
        <button className=' text-4xl text-[#93A5D4] h-[30px] w-[30px] flex items-center justify-center  content-center border-none bg-none cursor-pointer outline-none select-none' onClick={handleDecrement}><p className='mb-1'></p></button>
        <div className="lev_value w-[50px] h-9 font-medium flex flex-wrap ">
          <input 
              className={`${Number(max)<10?'w-[26px]':Number(max)<100?'w-[32px]':'w-[42px]'} duration-200
             bg-[#223056] rounded-md  outline-none text-2xl text-[#93A5D4] text-right`}
              type="text"
              value={max}
              disabled
              />
              
          <span className=' w-[8px] text-[#93A5D4] text-2xl select-none mt-[2px]'>x</span>
        </div>
        <button className=' text-3xl text-[#93A5D4] h-[30px] w-[30px] flex items-center justify-center  content-center border-none bg-none cursor-pointer outline-none select-none' onClick={handleIncrement}><p className='mb-1'></p></button>
      </div>
      :<div className={`w-[150px] h-[60px] bg-[#223056]  rounded-md flex items-center justify-around `}>
      <button className=' text-4xl text-[#93A5D4] h-[30px] w-[30px] flex items-center justify-center  content-center border-none bg-none cursor-pointer outline-none select-none' onClick={handleDecrement}><p className='mb-1'>-</p></button>
      <div className="lev_value w-[50px] h-9 font-medium flex flex-wrap ">
        <input
            className={`${Number(value)<10?'w-[26px]':Number(value)<100?'w-[32px]':'w-[42px]'} duration-200 bg-[#223056] rounded-md outline-none text-2xl text-[#93A5D4] text-right`}
            type="text"
            value={value.toString()}
            onChange={handleInputChange}
            />
        <span className=' w-[8px] text-[#93A5D4] text-2xl select-none'>x</span>
      </div>
      <button className=' text-3xl text-[#93A5D4] h-[30px] w-[30px] flex items-center justify-center  content-center border-none bg-none cursor-pointer outline-none select-none' onClick={handleIncrement}><p className='mb-1'>+</p></button>
    </div>}
    </div>
  );
};

export default LeverageInput;
