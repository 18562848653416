import React, { useEffect, useState } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { useNavigate } from 'react-router-dom';

const Room = () => {
    const {tg} = useTelegram();
    const navigate = useNavigate();
    const [apiKey,setApiKey] = useState(null);
    const [secretKey,setSecretKey] = useState(null);
    const [isReady,setIsReady] = useState(false);

    //Getting KEYS
    useEffect(()=>{
        tg.CloudStorage.getItem('api_key', (error:any, retrievedValue:any) => {
            if (error) {
                console.error('Failed to retrieve value:', error);}
            else{
                setApiKey(retrievedValue)
            }})
        tg.CloudStorage.getItem('secret_key', (error:any, retrievedValue:any) => {
            if (error) {
                console.error('Failed to retrieve value:', error);}
            else{
                setSecretKey(retrievedValue);
                setIsReady(true);
            }});
            
        },[tg]);

    useEffect(()=>{
        if (isReady){
            if (apiKey && secretKey){
                navigate('/app');
            }else{
                navigate('/auth')
            }
        
        }
    },[isReady])

    

    return (
        <div className='w-[100vw] h-[100vh] bg-[#151B28] flex justify-center items-center content-center'>
            <div className='text-blue-100 text-xl'>loading...</div>
        </div>
    );
};

export default Room;