import React, { ChangeEvent } from 'react';

type InputProps = {
    label: string;
    className?: string;
    value: any;
    setValue: (value: any) => void;
    [x: string]: any; // for any other props
};

const Input: React.FC<InputProps> = ({ label, className, value, setValue, type, ...props }) => {

    return (
        <div className={`w-[150px] h-[71px] ${className}`}>
            <p className='text-[#37456C]'>{label}</p>
            <input 
                type={type}
                className={` appearance-none w-[150px] h-[40px] bg-[#223056] text-[#93A5D4]  rounded-md flex items-center justify-around outline-none pl-3`}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                {...props}
            />
        </div>
    );
};

export default Input;
